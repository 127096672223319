import React from 'react';
import './FromTheNews.scss';

const FromTheNews = () => (
  <div className="pt-4">
    <div className="from-news-title">
      <span>
        From the
      </span>
      <br />
      <span>NEWS</span>
    </div>
    <div className="media media-count-down">
      <div className="count-down">
        01
      </div>
      <div className="media-body">
        <h5 className="mt-0">
          <a target="_blank" href="https://www.datviet.com/pham-thien-an-tai-nang-tre-duoc-phat-hien-tai-cannes/" rel="noopener noreferrer" aria-label="Phạm Thiên Ân, tài năng trẻ được phát hiện tại Cannes">
            Phạm Thiên Ân, tài năng trẻ được phát hiện tại Cannes
          </a>
        </h5>
        <div className="text-muted mb-3">
        Datviet.com
        </div>
        Đươc tuyển chọn để tranh giải trong khuôn khổ chương trình Quinzaine des Réalisateurs (Hai tuần của các đạo diễn), bộ phim ngắn « Stay Awake, Be Ready » (Hãy tỉnh thức và sẵn sàng) của đạo diễn trẻ người Việt Phạm Thiên Ân đã được trao giải Illy dành cho phim ngắn hay nhất.
      </div>
    </div>
    <div className="media media-count-down">
      <div className="count-down">
        02
      </div>
      <div className="media-body">
        <h5 className="mt-0">
          <a target="_blank" href="http://vietnamnews.vn/life-style/519251/vn-film-to-premiere-at-cannes-film-fest.html" rel="noopener noreferrer" aria-label="VN film to premiere at Cannes Film Fest">
            VN film to premiere at Cannes Film Fest
          </a>

        </h5>
        <div className="text-muted mb-3">
        vietnamnews.vn
        </div>
        Hãy Thức Tỉnh và Sẵn Sàng (Stay Awake, Be Ready) by Phạm Thiên Ân will premiere in the Quinzaine des Réalisateurs (Directors' Fortnight), an independent section held in parallel with the Cannes Film Festival in France in May.
      </div>
    </div>
    <div className="media media-count-down">
      <div className="count-down">
        03
      </div>
      <div className="media-body">
        <h5 className="mt-0">
          <a target="_blank" href="https://thanhnien.vn/van-hoa/phim-ngan-cua-nhieu-dao-dien-tre-chu-du-lien-hoan-phim-quoc-te-1009492.html" rel="noopener noreferrer" aria-label="Phim ngắn của nhiều đạo diễn trẻ ‘chu du’ liên hoan phim quốc tế">
            Phim ngắn của nhiều đạo diễn trẻ ‘chu du’ liên hoan phim quốc tế
          </a>
        </h5>
        <div className="text-muted mb-3">
        thanhnien.vn
        </div>
        Bộ phim Câm lặng (The Mute) của đạo diễn Phạm Thiên Ân và bộ phim Mother, Daughter, Dreams của đạo diễn Dương Diệu Linh được lựa chọn tham dự và công chiếu tại liên hoan phim quốc tế tại Mỹ và Hàn Quốc.
      </div>
    </div>
  </div>
);

export default FromTheNews;
