import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Layout from '../components/layout';
import SocialRef from '../components/SocialRef';
import FromTheNews from '../components/FromTheNews';
import './single-film.scss';

const FilmCamLang = () => (
  <Layout className="single-film">
    <section className="film-hero">
      <Container>
        <div className="film-hero-inner">
          <Row>
            <Col xs="12" md="5" className="mb-4">
            <div className="text-center">
              <Fade>
                <img loading="lazy" src="/files/banner-the-mute.jpg" alt="THE MUTE - CÂM LẶNG (2018) Film ngắn của đạo diễn Phạm Thiên Ân"/>
              </Fade>
            </div>
            </Col>
            <Col>
              <h1 className="film-title">
                THE MUTE – CÂM LẶNG (2018)
              </h1>
              <div className="film-desc">
                A girl tries to find the answer about love before her big day, on a rainy night.
              </div>
              <div className="film-specs">
                <div>
                  <strong>Genres:</strong> Short | Drama
                </div>
                <div>
                  <strong>Language:</strong> Vietnamese
                </div>
                <div>
                  <strong>Release Date:</strong> 20 June 2018 (USA)
                </div>
                <div>
                  <strong>Director:</strong> Pham Thien An
                </div>
                <div>
                  <strong>Writer:</strong> Pham Thien An
                </div>
                <div>
                  <strong>Key Cast:</strong> Pham Le Kim Ngoc, Dan Le, Nguyen Tan, Nguyen Lam Quynh Han
                </div>
              </div>
              <SocialRef
                facebook="https://www.facebook.com/themutephamthienan/"
                imdb="https://www.imdb.com/title/tt7938818/"
                vimeo="https://vimeo.com/265012499"
              />
            </Col>
          </Row>
        </div>
      </Container>
    </section>
    <Container>
      <Row>
        <Col md="8">
          <div className="video-responsive">
            <iframe src="https://player.vimeo.com/video/265012499" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
            <p><a href="https://vimeo.com/265012499">C&Acirc;M LẶNG | THE MUTE | Pham Thien An - Trailer</a> from <a href="https://vimeo.com/phamthienan">Pham Thien An</a> on <a href="https://vimeo.com">Vimeo</a>.</p>
            </div>
            <img className="mb-4" src="/files/canh-trong-film-the-mute.jpg" alt="Film scene THE MUTE – CÂM LẶNG (2018)"/>
            <img className="mb-4" src="/files/canh-trong-film-the-mute-2.jpg" alt="Film scene THE MUTE – CÂM LẶNG (2018)"/>
            <img className="mb-4" src="/files/canh-trong-film-the-mute-3.jpg" alt="Film scene THE MUTE – CÂM LẶNG (2018)"/>
            <img className="mb-4" src="/files/canh-trong-film-the-mute-4.jpg" alt="Film scene THE MUTE – CÂM LẶNG (2018)"/>
            <img className="mb-4" src="/files/canh-trong-film-the-mute-5.jpg" alt="Film scene THE MUTE – CÂM LẶNG (2018)"/>
          <FromTheNews />
        </Col>
        <Col md="4">
          <div className="widget">
            <div className="widget-title">
              Director's statement
            </div>
            <div className="widget-content">
            God is Love, Love is a Gift of God. Have the love between two people of the same sex been called a Gift from God? Homosexual marriage is not a new term, but it has not been seen seriously and clearly in many countries around the world, especially in Vietnam, where feudalism persists "Parents set where you sit". I mean this is the conflict between appetence and morality, between faith and ideology, that block all voices from the soul.
            </div>
          </div>

          <div className="widget">
            <div className="widget-title">
            Distributor
            </div>
            <div className="widget-content">
              <div>
              Lights On Film
              </div>
              <a rel="noopener noreferrer" aria-label="Lights On Film" href="https://www.lightsonfilm.com/" target="_blank">www.lightsonfilm.com</a>
            </div>
          </div>

          <div className="widget">
            <div className="widget-title">
            Festival Selection
            </div>
            <div className="widget-content">
              Palm Springs International ShortFest - USA (WORLD PREMIERE) <br/>
              Short Waves Festival - Poland (SECOND AWARD) <br/>
              The International Short Film Festival of Cyprus - Cyprus (HONORARY DISTINCTIONS) <br/>
              Internationale Kurzfilmtage Winterthur - Switzerland <br/>
              Tampere Film Festival - Finland <br/>
              Hong Kong International Film Festival - Hongkong <br/>
              Uppsala Internationella Kortfilmfestival - Sweden<br/>
              Leeds International Film Festival - UK <br/>
              Concorto Film Festival - Italy<br/>
              Encounters Short Film Festival - UK<br/>
              Aspen Shortsfest - USA<br/>
              Timishort Film Festival - Romania<br/>
              Tenerife Shorts - Spain<br/>
              Minimalen Kortfilmfestival - Norway<br/>
              Urbanworld Film Festival - USA<br/>
              Landshuter Kurzfilmfestival - Germany<br/>
              Brno 16 Film Festival - Czech Republic<br/>
              Festival Internacional de Curtas de Belo Horizonte - Brazil<br/>
              Bogotá Short Film Festival - Colombia<br/>
              LA Shorts Fest - USA<br/>
              Shorts Mexico - Mexico<br/>
            </div>
          </div>
          <div className="widget">
            <div className="widget-content">
              15min | Vietnam, USA | 2018<br/>
              Year of production 2018 <br/>
              Length 15 min <br/>
              Country Vietnam, USA <br/>
              Camera ARRI Alexa <br/>
              Format 1:1,85 <br/>
              Sound 5.1 <br/>
              Dialogue Vietnamese <br/>
            </div>
          </div>
          <div className="widget">
            <div className="widget-content">
              <strong>Director</strong> Pham Thien An <br/>
              <strong>Producers</strong> Pham Thien An, Tran Van Thi <br/>
              <strong>Production</strong> JKFILM <br/>
              <strong>Writer</strong> Pham Thien An <br/>
              <strong>Cinematographer</strong> Dinh Duy Hung <br/>
              <strong>Production Designer</strong> Huynh Phuong Hien <br/>
              <strong>Editor</strong> Pham Thien An <br/>
              <strong>Director Assistant</strong> Pham Thi Phuong Thao, Pham Thien Phuc <br/>
              <strong>Camera Assistant</strong> Do Tien Dat, Nguyen Tri Vuong Ba <br/>
              <strong>Sound Mixing</strong> Tamas Steger <br/>
              <strong>Sound Recording</strong> Le Quang Dao, Ti Con <br/>
              <strong>BTS Still</strong> Luu Binh An <br/>
              <strong>Key Cast</strong> Pham Le Kim Ngoc, Dan Le, Nguyen Tan, Nguyen Lam Quynh Han , Vu Trong Tuyen, Le Ngoc Toan
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default FilmCamLang;
